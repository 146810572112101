$ivory: #fffff8;
$nimble: #989ca0;
$pear: #609078;
$kevinGreen:#69ac7a;
$kevinMiddle: #9fdeaf;
$kevinWhite: #eff9f4;
@import url("https://fonts.googleapis.com/css?family=Pacifico");

body {
  margin: 0;
  font-family: "Pacifico";
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: keep-all;
  min-height: 100vh;
  font-size: calc(16px + 2vmin);
  padding: 0px 12px 0px 12px;
  background-image: linear-gradient(to bottom right, $kevinGreen, $kevinWhite);
}

.App > * {
  margin: 10px 0;
}

.flags {
  position: relative;
  top: 5px;
}

.flag {
  width: 64px;
  height: 64px;
  margin-right: 10px;
  margin-left: 10px;
}

.Couple {
  height: auto;
  width: auto;
  border-radius: 50%;
  min-width: 64px;
  min-height: 64px;
  max-height: 500px;
  max-width: 80%;
}

.title {
  font-size: calc(24px + 2vmin);
  margin-bottom: 0px;
  color: $pear;
}

.subtitle {
  margin-top: 0px;
  font-size: calc(20px + 2vmin);
  color: $pear;
}

.countDown {
  &__Title {
    color: $pear;
    font-size: calc(16px + 2vmin);
  }
  &__Time {
    color: $pear;
    font-size: calc(12px + 2vmin);
  }
}

.aspect-ratio-box {
  width: 90%;
  max-width: 720px;
  max-height: 405px;
}

.aspect-ratio-box-inner {
  height: 0;
  padding-top: 56.25%;
  position: relative;
}

.youtube {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.message {
  width: 80%;
  color: $pear;
}


.button {
  position: relative;
  border: none;
  display: inline-block;
  box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.2),
        inset 0px -5px 0px 0px rgba(0,0,0,0.3),
     inset 0px 2px 0px 0px rgba(255,255,255,0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  margin: 36px;
  width: 160px;
  height: 48px;
  font-size: 24px;
  color: black;
}

a:link {
  color:inherit;
}

.button:active {
  box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.2),
      inset 0px -2px 0px 0px rgba(0,0,0,0.3),
      inset 0px 2px 0px 0px rgba(255,255,255,0.5);
  top: 3px;
}

.button div {
  padding: 10px 20px 12px 20px;
  color: rgba(0,0,0,0.7);
  text-shadow: 1px 1px 0px rgba(255,255,255,0.4);
}

.button:active div {
  padding-bottom: 9px;
}

.wood {
  background: url(http://1.bp.blogspot.com/-tXavBQFjNFQ/VMSaYaq7PtI/AAAAAAAADSc/awMPRe3yu5A/s1600/wood_pattern.png);
}